const Data = {
    menuItems: [
        // {
        //     name: "Features",
        //     path: "/#features",
        //     offset: "-50"
        // },
        {
            name: "Modules",
            path: "/#modules",
            offset: "-50"
        },
        {
            name: "Pricing",
            path: "/#pricing",
            offset: "-50"
        },
        // {
        //     name: "Download",
        //     path: "/#getapp",
        //     offset: "-50"
        // },
        {
            name: "Contact",
            path: "/inquiry",
            offset: "-50"
        },
        {
            name: "Login",
            path: "https://pwa.mycorp.io",
            offset: "-50"
        }
    ]
};
export default Data;
