import React from "react";
import { Link } from "gatsby";

import {
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
    FaEnvelope,
    FaPinterestP,
    FaInstagram
} from "react-icons/fa";

import { Container, Row, Col } from "reusecore/Layout";
import Button from "reusecore/Button";
import Particle from "reusecore/Particle";

import FooterBGTwo from "assets/images/app/footer/footer-particle-two.png";

import FotterWrapper from "./footer.style";

const FooterClassic = () => {
    return (
        <FotterWrapper>
            <img src={FooterBGTwo} alt="img" className="section__particle one" />
            <Particle />
            <Container>
                <Row>
                    {/* <Col xs={12} sm={6} lg={3}>
                        <div className="footer-widgets first">
                            <Link className="footer-logo" to="#">
                                <h1 style={{
                                    color: '#2C0075',
                                    fontSize: 36
                                }}>MyCorp</h1>
                            </Link>
                            <form className="subscribe-form">
                                <input type="text" placeholder="Drop us email..." />
                                <Button>
                                    <FaEnvelope />
                                </Button>
                            </form>
                        </div>
                    </Col> */}
                    <Col xs={12} sm={6} lg={4}>
                        <div className="footer-widgets">
                            <h3 className="widget-title">Get the App</h3>
                            <ul className="widget-catagory">
                                {/* <li>
                                    <Link to="#">Company</Link>
                                </li> */}
                                {/* <li>
                                    <Link to="#">Android App</Link>
                                </li>
                                <li>
                                    <Link to="#">iOS App</Link>
                                </li> */}
                                <li>
                                    <Link to="#">Installable Web</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <div className="footer-widgets">
                            <h3 className="widget-title">Help</h3>
                            <ul className="widget-catagory">
                                <li>
                                    <Link to="#">Privacy</Link>
                                </li>
                                {/* <li>
                                    <Link to="#">Reporting</Link>
                                </li> */}
                                <li>
                                    <Link to="#">Terms & Condition</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4}>
                        <div className="footer-widgets">
                            {/* <h3 className="widget-title">Follow US</h3> */}
                            <ul className="social">
                                {/* <li>
                                    <Link to="#">
                                        <FaFacebookF />
                                    </Link>
                                </li> */}
                                {/* <li>
                                    <Link to="#">
                                        <FaTwitter />
                                    </Link>
                                </li> */}
                                {/* <li>
                                    <Link to="#">
                                        <FaInstagram />
                                    </Link>
                                </li> */}
                                {/* <li>
                                    <Link to="#">
                                        <FaLinkedinIn />
                                    </Link>
                                </li> */}
                                {/* <li>
                                    <Link to="#">
                                        <FaPinterestP />
                                    </Link>
                                </li> */}
                            </ul>
                            <p className="copyright-text">
                                Copyright &#169; 2021 developed by <Link to="https://cyder.page">Cyder SG</Link>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </FotterWrapper>
    );
};

export default FooterClassic;
